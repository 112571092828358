<!--
   Created by Terence on 2022/9/8 - 11:16
-->
<template>
  <div class="login-page">
    <div class="content">
      <div class="content-bluebg" />
      <div class="content-whitebg">
        <div class="input-wrp">
          <input
            v-if="loginFormData.step === STEP_ENUM.INPUT_PHONE"
            class="input-wrp-phone"
            placeholder="输入手机号"
            type="tel"
            maxlength="11"
            v-model="loginFormData.phone"
          />

          <!--        <sms-input />-->

          <div
            class="input-wrp-code"
            v-else-if="loginFormData.step === STEP_ENUM.INPUT_SMS_CODE"
          >
            <input
              class="input-wrp-code-form"
              type="tel"
              v-model="loginFormData.smsCode"
            />
            <van-password-input
              :mask="false"
              focused
              :value="loginFormData.smsCode"
              :gutter="10"
            />
          </div>
          <!--        <van-number-keyboard-->
          <!--          :show="true"-->
          <!--        />-->
          <input type="tel" />
        </div>
        <div class="btn-wrp">
          <!--        color="linear-gradient(150.58deg, #2B1AC0 0%, #7247DC 100%)"-->
          <van-button
            type="primary"
            size="large"
            @click="handleClickBtn"
            :disabled="!!loginFormData.countdown"
          >
            {{
              !!loginFormData.countdown
                ? `重新发送(${loginFormData.countdown})`
                : "获取验证码"
            }}
          </van-button>
        </div>
      </div>
      <div class="content-agreement">
        <div class="content-agreement-checkbox">
          <van-checkbox
            v-model="loginFormData.isCheckedAgreement"
            shape="square"
          />
        </div>
        <div class="content-agreement-text">
          我已阅读并同意了<a
            target="_blank"
            href="https://config-cdn.qiandaoapp.com/docs/#/edgy_user_agreement"
            >《回响科技隐私协议》</a
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, watch } from "vue";
import {
  Button as VanButton,
  Checkbox as VanCheckbox,
  PasswordInput as VanPasswordInput,
  // NumberKeyboard as VanNumberKeyboard,
  showSuccessToast,
  showFailToast,
  showConfirmDialog,
  showLoadingToast,
  closeToast,
} from "vant";
import { useRouter } from "vue-router";
import { getQuery } from "@/utils/url";
import { isDev } from "@/utils/env-related";
import {
  setStorageToken,
  setRefreshToken,
  setExpireTimestamp,
  setCookieToken,
} from "@/utils/authorize";
import ApiLogin from "@/apis/login";
import { storageKeys } from "@/constant/storage";
import { REQUEST_OK } from "@/constant/network";

const router = useRouter();
const routerRef = ref(router);

const STEP_ENUM = {
  INPUT_PHONE: "inputPhone",
  INPUT_SMS_CODE: "inputSMSCode",
};

const SMS_CODE_LENGTH = 6;

const loginFormData = ref({
  step: STEP_ENUM.INPUT_PHONE,
  isCheckedAgreement: false,
  phone: "",
  smsCode: "",
  countdown: 0,
});

const activateCountdown = () => {
  const timer = setInterval(() => {
    loginFormData.value = {
      ...loginFormData.value,
      countdown: loginFormData.value.countdown - 1,
    };
    if (loginFormData.value.countdown <= 0) {
      clearInterval(timer);
    }
  }, 1000);
};

watch(
  () => loginFormData.value.smsCode,
  (val) => {
    if (val.length === SMS_CODE_LENGTH) {
      handleLogin();
    }
  },
  {
    immediate: false,
  }
);

const handleLogin = async () => {
  const { phone, smsCode } = loginFormData.value;
  showLoadingToast({
    message: "加载中...",
    forbidClick: true,
    duration: 0,
  });
  const res = await ApiLogin.loginWithSmsCode({ phone, code: smsCode });
  const routerQuery = getQuery();
  const { callerType, callerRedirectUrl } = routerQuery;

  if (res) {
    const { token, refreshToken, expiresIn } = res.data || {};

    setStorageToken(token);
    setRefreshToken(refreshToken);
    setExpireTimestamp(expiresIn);
    setCookieToken(token);
    if (callerType && callerRedirectUrl) {
      window.location.href = decodeURIComponent(callerRedirectUrl);
      return;
    }

    const userInfoRes = await ApiLogin.getUserInfo({});
    if (userInfoRes.code !== REQUEST_OK) {
      showFailToast("获取用户信息失败, 请刷新页面重新登录");
      return;
    }
    const resultInfo = {
      phone: loginFormData.value.phone,
      ...(userInfoRes?.data?.account || {}),
    };
    closeToast();
    localStorage.setItem(storageKeys.USER_INFO, JSON.stringify(resultInfo));
    const isCertified = userInfoRes?.data?.account?.isCertified;

    const u = navigator.userAgent;
    const isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android终端或者uc浏览器
    // var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    // if (isCertified) {
    // router.push(`/token-detail?tokenId=${"523452545113261640"}`);

    if (isAndroid) {
      window.location.href = document.referrer; // rnm qq浏览器
      const tempPath = `/${document.referrer
        .split("?")[0]
        .split("/")
        .pop()}?${document.referrer.split("?").pop()}`;
      router.push(tempPath);
    } else {
      router.back();
    }
    // } else {
    //   router.replace("/real-name-auth");
    // }
  } else {
    showFailToast("登录失败");
    closeToast();
  }
};

const invokeSmsCode = async () => {
  const { phone } = loginFormData.value;
  const res = await ApiLogin.getSMSCode({ phone });
  if (res) {
    isDev && alert(res.data.code);
    // 此接口无具体response,接收到res就是access
    showSuccessToast("验证码已发送");
    loginFormData.value = {
      ...loginFormData.value,
      step: STEP_ENUM.INPUT_SMS_CODE,
      countdown: 60,
    };
    activateCountdown();
  }
};

const handleClickBtn = async () => {
  // const res = await ApiLogin.getSMSCode({phone: '13382001845'})
  const { step, phone, smsCode, countdown, isCheckedAgreement } =
    loginFormData.value;
  const phoneRegex = new RegExp(/^[1][3,4,5,6,7,8,9][0-9]{9}$/);
  const isPhoneValided = phoneRegex.test(phone);
  // debugger;
  if (!isCheckedAgreement && isPhoneValided) {
    showConfirmDialog({
      title: "",
      message: "发送短信即代表您已阅读并同意平台协议，是否确认？",
    })
      .then(() => {
        loginFormData.value.isCheckedAgreement = true;
        invokeSmsCode();
        // on confirm
      })
      .catch(() => {
        // on cancel
      });
    return;
  }

  if (
    step === STEP_ENUM.INPUT_PHONE ||
    (step === STEP_ENUM.INPUT_SMS_CODE &&
      countdown === 0 &&
      smsCode.length !== SMS_CODE_LENGTH)
  ) {
    if (isPhoneValided) {
      invokeSmsCode();
    } else {
      showFailToast("手机号不存在，请重新输入");
    }
  }
  // else if (step === STEP_ENUM.INPUT_SMS_CODE && smsCode.length === 6) {
  // }
};
</script>

<style lang="scss">
.login-page {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(150.58deg, #2b1ac0 0%, #7247dc 100%);
  .content {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 726rpx;
    //height: 1152rpx;
    height: 1250rpx;
    //background-color: skyblue;
    &-bluebg {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 85%;
      height: 100%;
      background-size: 100%;
      background-position: left top;
      background-repeat: no-repeat;
      background-image: url("https://cdn.qiandaoapp.com/ued/4a4a6ef9d5a56a2bc1eadbc16effd856.png");
      //background-image: url("https://cdn.qiandaoapp.com/ued/4a4a6ef9d5a56a2bc1eadbc16effd856.png!lfit_w1080_h1080_png");
    }
    &-whitebg {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1250rpx;
      background-size: 100%;
      background-position: left top;
      background-repeat: no-repeat;
      background-image: url("https://cdn.qiandaoapp.com/ued/9b918f2d3a561dced44edf2f40300ad7.png");
      //background-image: url("https://cdn.qiandaoapp.com/ued/9b918f2d3a561dced44edf2f40300ad7.png!lfit_w1080_h1080_png");
    }
    .input-wrp {
      //box-sizing:border-box;
      //border-radius:16rpx;
      //
      //box-sizing:border-box;
      //border:1px solid rgba(0, 0, 0, 0.1);
      //border-radius:16rpx;
      &-phone {
        transition: all 0.1s;
        //padding-left: 32rpx;
        text-indent: 32rpx;
        position: absolute;
        left: 64rpx;
        top: 242rpx;
        width: 480rpx;
        height: 112rpx;
        background: white;
        box-sizing: content-box;
        border: 2rpx solid rgba(0, 0, 0, 0.1);
        border-radius: 16rpx;
        font-size: 64rpx;
        font-family: "Roboto";
        font-weight: 900;
      }
      &-phone:focus {
        border: 2rpx solid #2b1ac0;
      }
      &-phone::-webkit-input-placeholder {
        font-weight: 400;
        font-size: 64rpx;
        line-height: 112rpx;
        color: rgba(0, 0, 0, 0.4);
      }
      &-code {
        position: absolute;
        left: 64rpx;
        top: 242rpx;
        width: 480rpx;
        //background-color: skyblue;
        &-form {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: red;
          z-index: 1;
          opacity: 0;
        }
      }
    }
    .btn-wrp {
      width: 480rpx;
      position: absolute;
      left: 64rpx;
      top: 476rpx;
    }
    &-agreement {
      position: absolute;
      left: 64rpx;
      top: 856rpx;
      display: flex;
      align-items: center;
      &-checkbox {
        .van-checkbox {
          //border-radius: 8rpx;
        }
      }
      &-text {
        margin-left: 8rpx;
        //width:410rpx;
        font-weight: 400;
        font-size: 20rpx;
        //line-height: 28rpx;
        color: rgba(0, 0, 0, 0.3);
        a {
          color: var(--primary-purple);
          font-weight: bold;
        }
      }
    }
  }
}

:root:root {
  --van-button-border-width: 0;

  --van-password-input-height: 112rpx;
  --van-password-input-margin: 0;
  --van-password-input-font-size: 64rpx;
  --van-password-input-cursor-color: var(--primary-purple);
  --van-password-input-cursor-width: 4rpx;
  --van-password-input-cursor-height: 64rpx;
  //--van-password-input-cursor-duration: 0.8s;
  //--van-password-input-radius: 8rpx;

  .van-button--primary {
    font-weight: 400;
    //--van-button-primary-border-color: yellow;
  }
  .input-wrp-code {
    li {
      position: relative;
      margin-left: 6rpx !important;
      font-family: "Roboto";
      font-weight: 900;
      border-radius: 8rpx;
      border: 2rpx solid rgba(0, 0, 0, 0.1);
    }
    li:first-child {
      margin-left: 0 !important;
    }
    .van-password-input__cursor {
      position: absolute;
      top: 50%;
      left: 30%;
      transform: translateY(-50%);
    }
    .van-password-input__item--focus {
      transition: all 0.3s;
      border: 2rpx solid var(--primary-purple);
    }
  }
  //--van-checkbox-checked-icon-color: red;
}
</style>
